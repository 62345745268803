.active-link {
	color: white;
	/* background-color: brown; */
	border-bottom-style: solid;
	border-bottom-color: white;
}
.not-home-active {
	color: #1c4e84;
	/* background-color: green; */
	border-bottom-style: solid;
	border-bottom-color: #1c4e84;
}
