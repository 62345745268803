@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;800&display=swap");
 */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600;700;800&display=swap');
* {
	font-family: 'Poppins', sans-serif;
	padding: 0;
	margin: 0;
}

.antd {
	font-family: 'Poppins', sans-serif;
}

.App {
	padding: 0;
	margin: 0;
}

.index {
	margin: 0;
	padding: 0;
}

/* .ant-menu-item:hover {
	color: red;
} */

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex-col-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
/* .ant-image-preview-img {
	z-index: 1025;
	width: 90vh;
	height: 50vh;
} */
/* .ant-image-preview-operations {
	background-color: #1c4e84;
} */
.custom-info-window {
	padding: 10px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
